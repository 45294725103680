import {
  LAYER_KEY__INVENTORY_GAS_NETWORK,
  LAYER_KEY__INVENTORY_HEAT_NETWORK,
  LAYER_KEY__EXISTING_NETWORK_AREAS,
  LAYER_KEY_EXISTING_HYDROGEN_NETWORK,
} from '../layers/inventoryNetwork';
import {
  heatSourceInventorySwitch,
  heatingCenterInventorySwitch,
  gridFeedPointInventorySwitch,
} from './heatSource';
import { infraModules } from '@/configs/infra-modules';

export const SWITCH_ID__INVENTORY_HEAT_NETWORK = 'LC_INVENTORY_HEAT_NETWORK';
export const SWITCH_ID__INVENTORY_GAS_NETWORK = 'LC_INVENTORY_GAS_NETWORK';
export const SWITCH_ID__EXISTING_NETWORK_AREAS = 'LC_EXISTING_NETWORK_AREAS';
export const SWITCH_ID__EXISTING_HYDROGEN_NETWORK =
  'LC_EXISTING_HYDROGEN_NETWORK';

export default {
  title: 'Bestandsinfrastruktur',
  module: infraModules.scenarios,
  items: [
    {
      title: 'Fernwärme',
      disabledByEmptyModel: 'ExistingNetworkPart',
      onLayers: [LAYER_KEY__INVENTORY_HEAT_NETWORK],
      switchId: SWITCH_ID__INVENTORY_HEAT_NETWORK,
      itemClass: 'switch',
    },
    {
      title: 'Erdgas',
      disabledByEmptyModel: 'ExistingGasNetworkPart',
      onLayers: [LAYER_KEY__INVENTORY_GAS_NETWORK],
      switchId: SWITCH_ID__INVENTORY_GAS_NETWORK,
      itemClass: 'switch',
    },
    heatSourceInventorySwitch,
    heatingCenterInventorySwitch,
    gridFeedPointInventorySwitch,
    {
      title: 'Wärmenetzgebiete',
      tooltip:
        'Flächenbezogene Informationen zum Wärmenetz, wenn eine leitungsliniengetreue Darstellung nicht möglich ist',
      disabledByEmptyModel: 'ExistingNetworkAreas',
      onLayers: [LAYER_KEY__EXISTING_NETWORK_AREAS],
      switchId: SWITCH_ID__EXISTING_NETWORK_AREAS,
      itemClass: 'switch',
    },
    {
      title: 'Wasserstoffnetzgebiete',
      tooltip:
        'Flächenbezogene Informationen zum Wasserstoffnetz, wenn eine leitungsliniengetreue Darstellung nicht möglich ist',
      disabledByEmptyModel: 'ExistingHydrogenNetwork',
      onLayers: [LAYER_KEY_EXISTING_HYDROGEN_NETWORK],
      switchId: SWITCH_ID__EXISTING_HYDROGEN_NETWORK,
      itemClass: 'switch',
    },
  ],
};
