<template>
  <div
    v-show="panelData.chips.length > 1"
    class="bg-infra-highlight-25 border-t-2 border-infra-lines"
    style="width: 366px"
  >
    <div class="flex flex-col py-5 px-[10px]">
      <div class="flex">
        <div v-for="(chip, index) in panelData.chips" :key="chip.title">
          <div
            v-if="allowed(chip)"
            class="custom-chip"
            :class="{
              'rounded-l-lg': index === 0,
              'rounded-r-lg': index === panelData.chips.length - 1,
              'bg-infra-highlight-700': activeChipIndex === index,
              'bg-infra-highlight-500': activeChipIndex !== index,
            }"
            @click="activeChipIndex = index"
          >
            <VTooltip
              :model-value="tooltipActive === chip.title"
              location="top"
              activator="parent"
              offset="-5"
            >
              {{ chip.title }}
            </VTooltip>
            <Component :is="iconsMap[chip.title]" fill="white" size="16" />
          </div>
        </div>
      </div>
      <h6 class="text-infra-highlight-800 mt-[10px]">
        {{ panelData.chips[activeChipIndex].title }}
      </h6>
    </div>
  </div>

  <InfoPanelData
    v-if="panelData"
    :panel-data="panelData"
    :active-chip-index="activeChipIndex"
    :feature="feature"
    @close-info-panel="emit('close-info-panel')"
  />
</template>

<script setup>
import { ref, shallowRef, watch } from 'vue';
import InfoPanelData from '@/features/map/info-panel/InfoPanelData.vue';
import { useModuleStore } from '@/configs/module-store';
import CalculatorIcon from '@/components/storybook/src/assets/icons/CalculatorIcon.vue';
import HouseIcon from '@/components/storybook/src/assets/icons/HouseIcon.vue';
import HeatCircleFilledIcon from '@/components/storybook/src/assets/icons/HeatCircleFilledIcon.vue';
import EditPointHeatSourceFilledIcon from '@/components/storybook/src/assets/icons/EditPointHeatSourceFilledIcon.vue';
import PotentialsFilledIcon from '@/components/storybook/src/assets/icons/PotentialsFilledIcon.vue';
import VorranggebieteFilledIcon from '@/components/storybook/src/assets/icons/VorranggebieteFilledIcon.vue';
import bulletListIcon from '@/components/storybook/src/assets/icons/BulletListIcon.vue';

const emit = defineEmits(['close-info-panel']);
const props = defineProps({
  panelData: {
    type: Object,
    default: null,
  },
  feature: {
    type: Object,
    default: null,
  },
});

const iconsMap = shallowRef({
  Auswertung: CalculatorIcon,
  Gebäudestruktur: HouseIcon,
  Wärmequellen: HeatCircleFilledIcon,
  Wärme: EditPointHeatSourceFilledIcon,
  Zielszenarien: CalculatorIcon,
  Gebäude: HouseIcon,
  Potenziale: PotentialsFilledIcon,
  Vorranggebiete: VorranggebieteFilledIcon,
  Projektparameter: bulletListIcon,
});

const activeChipIndex = ref(0);
const tooltipActive = ref(null);

const moduleStore = useModuleStore();

function allowed(chip) {
  return moduleStore.isActive(chip.module);
}

watch(
  () => props.panelData,
  (newPanelData, oldPanelData) => {
    if (
      newPanelData.name !== oldPanelData.name ||
      newPanelData.name === 'Projektgebiet Cluster'
    ) {
      activeChipIndex.value = 0;
    }
  },
);
</script>

<style scoped lang="scss">
.custom-scrollbar-width {
  scrollbar-width: thin;
}

.custom-chip {
  padding: 8px 12px;
  width: 44px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    @apply bg-infra-highlight-700 #{!important};
  }
}

:deep(.v-overlay__content) {
  @apply bg-infra-highlight-50;
  @apply text-infra-highlight-900;
}
</style>
