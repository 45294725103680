import { fetchData } from './utils';
import { mergeFeatureDataAndLayerConfig } from '@/features/map/info-panel/panel-data/panel-data-calculator';
import {
  LAYER_KEY__HEAT_SOURCE_INVENTORY,
  LAYER_KEY__HEAT_SOURCE_POTENTIAL,
  LAYER_KEY__HEATING_CENTER_INVENTORY,
  LAYER_KEY__HEATING_CENTER_POTENTIAL,
  LAYER_KEY__GRID_FEED_POINT_INVENTORY,
  LAYER_KEY__GRID_FEED_POINT_POTENTIAL,
  LAYER_KEY__WIND_POWER_PLANT,
  LAYER_KEY__SOLAR_FREE_POWER_PLANT,
  LAYER_KEY__BIOMASS_POWER_PLANT,
  LAYER_KEY__GEOTHERMAL_POWER_PLANT,
} from '@/configs/layers/constants.js';
import {
  LAYER_KEY__BIOMASS,
  LAYER_KEY__PV_FREE,
  LAYER_KEY__PV_ROOF,
  LAYER_KEY__SOLAR_THERMAL_FREE,
  LAYER_KEY__SOLAR_THERMAL_ROOF,
  LAYER_KEY__WASTE_HEAT,
  LAYER_KEY__WIND,
} from '@/configs/layers/potentials';

export class SimplePanel {
  layerConfigs = {
    [LAYER_KEY__HEAT_SOURCE_INVENTORY]: { url: 'heatprojects/heat-source' },
    [LAYER_KEY__HEAT_SOURCE_POTENTIAL]: { url: 'heatprojects/heat-source' },
    [LAYER_KEY__HEATING_CENTER_INVENTORY]: {
      url: 'heatprojects/heating-center',
    },
    [LAYER_KEY__HEATING_CENTER_POTENTIAL]: {
      url: 'heatprojects/heating-center',
    },
    [LAYER_KEY__GRID_FEED_POINT_INVENTORY]: {
      url: 'heatprojects/grid-feed-point',
    },
    [LAYER_KEY__GRID_FEED_POINT_POTENTIAL]: {
      url: 'heatprojects/grid-feed-point',
    },
    [LAYER_KEY__WIND_POWER_PLANT]: { url: 'basedata/wind-power-plant' },
    [LAYER_KEY__SOLAR_FREE_POWER_PLANT]: {
      url: 'basedata/solar-free-power-plant',
    },
    [LAYER_KEY__BIOMASS_POWER_PLANT]: { url: 'basedata/biomass-power-plant' },
    [LAYER_KEY__GEOTHERMAL_POWER_PLANT]: {
      url: 'basedata/geothermal-power-plant',
    },

    [LAYER_KEY__PV_ROOF]: { url: 'potentials/PotentialPVRoof' },
    [LAYER_KEY__PV_FREE]: { url: 'potentials/PotentialPVFree' },
    [LAYER_KEY__SOLAR_THERMAL_FREE]: {
      url: 'potentials/PotentialSolarThermalFree',
    },
    [LAYER_KEY__SOLAR_THERMAL_ROOF]: {
      url: 'potentials/PotentialSolarThermalRoof',
    },
    [LAYER_KEY__WASTE_HEAT]: { url: 'potentials/PotentialWasteHeat' },
    [LAYER_KEY__BIOMASS]: { url: 'potentials/PotentialBiomass' },
    [LAYER_KEY__WIND]: { url: 'potentials/PotentialWind' },
  };

  layerIds = Object.keys(this.layerConfigs);

  async requestFeatureData(feature, scenarioId) {
    const featureId = feature.properties.id;
    const layerId = feature.layer.id;

    const resp = await fetchData(
      `/api/${this.layerConfigs[layerId].url}/${featureId}`,
    );

    if (resp === null) return resp;

    const featureData = resp.data;
    featureData.layerId = layerId;
    return featureData;
  }

  calculatePanelData(featureData, scenarioYear, store) {
    const mainResult = featureData.result;
    const yearlyResult = [];
    const panelConfig =
      store.state.map.layerConfigs[featureData.layerId].sidebar;

    return mergeFeatureDataAndLayerConfig(
      mainResult,
      yearlyResult,
      scenarioYear,
      panelConfig,
      null,
      featureData.options,
    );
  }
}
