import {
  SWITCH_ID__BKG_RASTER,
  SWITCH_ID__BLOCK,
  SWITCH_ID__COUNTY,
  SWITCH_ID__CUSTOM,
  SWITCH_ID__DISTRICT,
  SWITCH_ID__FEDERAL_STATE,
  SWITCH_ID__GOVERNMENT_DISTRICT,
  SWITCH_ID__MUNICIPALITY,
} from '@/configs/layer-panel/aggregation';

export function getLayerConfig(
  layerKey,
  { routerName = 'district', visible = 'none' } = {
    routerName: 'district',
    visible: 'none',
  },
) {
  return {
    app_name: 'scenario',
    router_name: routerName,
    key_name: 'id',
    type: 'polygon',
    id: layerKey,
    sourceLayer: layerKey,
    layerId: layerKey,
    visibility: visible,
    dependsOnScenario: true,
    applyMunicipalityFilter: true,
    applyYearlyFilter: false,
  };
}

function createStyleConfig(minValue, minColor, maxValue, maxColor) {
  return {
    min: { value: minValue, color: minColor },
    max: { value: maxValue, color: maxColor },
  };
}

export function getStyle(
  emissionMax,
  heatDemandMax,
  heatDensityLineMax,
  heatDensityMax,
  emissionSavingMax,
) {
  return {
    emission: createStyleConfig(
      0,
      'rgba(236,226,240,0.7)',
      emissionMax,
      'rgba(28,144,153,0.7)',
    ),
    heatDemand: createStyleConfig(
      0,
      'rgba(254,232,200,0.7)',
      heatDemandMax,
      'rgba(227,74,51,0.7)',
    ),
    heatDensityLine: createStyleConfig(
      0,
      'rgba(254,232,200,0.7)',
      heatDensityLineMax,
      'rgba(227,74,51,0.7)',
    ),
    heatDensity: createStyleConfig(
      0,
      'rgba(254,232,200,0.7)',
      heatDensityMax,
      'rgba(227,74,51,0.7)',
    ),
    emissionSaving: createStyleConfig(
      0,
      'rgba(229,245,224,0.7)',
      emissionSavingMax,
      'rgba(49,163,84,0.7)',
    ),
  };
}

function createItem(key, unit = '', decimal = undefined, multiply = undefined) {
  return { key, unit, decimal, multiply };
}

export function getAggregationSidebar(name, subtitleKeys) {
  let vorrang = {};
  // add Vorranggebiete Chip with according expandables for stadtteil and block
  if (name === 'Block' || name === 'Stadtteil') {
    vorrang = {
      title: 'Vorranggebiete',
      active: false,
      items: [
        {
          title: 'Vorranggebiete',
          active: true,
          items: [
            createItem('priority_area'),
            createItem('manually_assigned'),
            createItem('base_heating_technology'),
            createItem('heating_category_target_year'),
          ],
        },
      ],
    };
  }
  if (name === 'Stadtteil') {
    vorrang.items.push({
      title: 'Ausbaulimits',
      active: false,
      items: [
        createItem('expansion_limit_heating_network'),
        createItem('expansion_limit_hydrogen_network'),
        createItem('expansion_limit_unit'),
        createItem('expansion_plan_heating_network'),
        createItem('expansion_plan_hydrogen_network'),
      ],
    });
  }
  const subtitles = [];
  for (const subtitleKey of subtitleKeys) {
    subtitles.push({ key: subtitleKey });
  }
  const sidebar = {
    name,
    subtitles,
    chips: [
      {
        title: 'Gebäude',
        active: true,
        items: [
          {
            title: 'Gebäudeinformationen',
            active: true,
            items: [
              createItem('buildings'),
              createItem('usable_area_m2', 'm²', 0),
              createItem('residential_area_m2', 'm²', 0),
              createItem('non_residential_area_m2', 'm²', 0),
              createItem('number_residents'),
              createItem('units_residential'),
              createItem('units_commercial'),
              createItem('heating_category'),
            ],
          },
          {
            title: 'Gebäudestruktur',
            active: false,
            items: [
              {
                panelItemType: 'pieChart',
                title: 'Gebäude nach Sektoren',
                titleInside: 'Gebäude',
                key: 'sector_count',
                unit: '',
              },
              {
                panelItemType: 'pieChart',
                title: 'Gebäude nach Baualtersklassen',
                titleInside: 'Gebäude',
                key: 'construction_year_count',
                unit: '',
              },
            ],
          },
        ],
      },
      {
        title: 'Wärme',
        active: false,
        items: [
          {
            title: 'Gebäudesanierung',
            active: true,
            items: [
              createItem('refurbished_buildings_count'),
              createItem('heat_demand_savings_kwh', 'MWh/a', 0, 0.001),
            ],
          },
          {
            title: 'Wärmebedarf',
            active: false,
            items: [
              createItem('heat_demand_kwh', 'MWh/a', 0, 0.001),
              createItem('heat_demand_kwh_usable_area_m2', 'kWh/m²', 0),
              createItem('heat_demand_kwh_residential_area_m2', 'kWh/m²', 0),
              createItem('heat_demand_kwh_per_resident', 'kWh/EW', 0),
              createItem('heat_density', 'MWh/ha', 0, 0.001),
              createItem('linear_heat_density_kwh_m', 'kWh/m', 0),
            ],
          },
          {
            title: 'Wärmebedarf - Charts',
            active: false,
            items: [
              {
                panelItemType: 'pieChart',
                title: 'Wärmebedarf nach Sektoren',
                titleInside: 'Wärmebedarf',
                key: 'demand_by_sector',
                unit: 'MWh',
                multiply: 0.001,
              },
              {
                panelItemType: 'pieChart',
                title: 'Wärmebedarf nach Heiztechnologie',
                titleInside: 'Wärmebedarf',
                key: 'demand_by_ht',
                unit: 'MWh',
                multiply: 0.001,
              },
            ],
          },
          {
            title: 'Emissionen',
            active: false,
            items: [
              createItem('co2_emissions', 't CO₂/a', 0),
              createItem('co2_emissions_per_resident', 't CO₂/EW', 0),
              createItem('co2_emissions_savings', 't CO₂/a', 0),
              createItem('co2_emissions_savings_percentage', '%', 0, 100),
            ],
          },
          {
            title: 'Emissionen - Charts',
            active: false,
            items: [
              {
                panelItemType: 'pieChart',
                title: 'Emissionen nach Sektoren',
                titleInside: 'Emissionen',
                key: 'emissions_by_sector',
                unit: 't CO₂',
              },
              {
                panelItemType: 'pieChart',
                title: 'Emissionen nach Heiztechnologie',
                titleInside: 'Emissionen',
                key: 'emissions_by_ht',
                unit: 't CO₂',
              },
            ],
          },
        ],
      },
      {
        title: 'Potenziale',
        active: false,
        items: [
          {
            title: 'Photovoltaik (Freifläche)',
            active: true,
            items: [
              {
                key: 'pv_globalradiation_kwh_per_m2',
                unit: 'kWh/m²',
                decimal: 0,
              },
              {
                key: 'pv_area_m2',
                unit: 'm²',
                decimal: 0,
              },
              {
                key: 'pv_operating_hours_h_per_year',
                unit: 'h/a',
                decimal: 0,
              },
              {
                key: 'pv_power_mw',
                unit: 'MW',
                decimal: 1,
              },
              {
                key: 'pv_production_mwh',
                unit: 'MWh/a',
                decimal: 0,
              },
              {
                key: 'pv_invest_euro',
                unit: 'Tsd. €',
                multiply: 0.001,
                decimal: 0,
              },
            ],
          },
          {
            title: 'Photovoltaik (Dach)',
            active: true,
            items: [
              {
                key: 'pv_roof_globalradiation_kwh_per_m2',
                unit: 'kWh/m²',
                decimal: 0,
              },
              {
                key: 'pv_roof_area_roof_available_m2',
                unit: 'm²',
                decimal: 0,
              },
              {
                key: 'pv_roof_operating_hours_h_per_year',
                unit: 'h/a',
                decimal: 0,
              },
              {
                key: 'pv_roof_power_mw',
                unit: 'MW',
                multiply: 0.001,
                decimal: 1,
              },
              {
                key: 'pv_roof_production_mwh',
                unit: 'MWh/a',
                multiply: 0.001,
                decimal: 0,
              },
              {
                key: 'pv_roof_invest_euro',
                unit: 'Tsd. €',
                multiply: 0.001,
                decimal: 0,
              },
            ],
          },
          {
            title: 'Solarthermie (Freifläche)',
            active: false,
            items: [
              {
                key: 'solarthermal_free_globalradiation_kwh_per_m2',
                unit: 'kWh/m²',
                decimal: 0,
              },
              {
                key: 'solarthermal_free_area_m2',
                unit: 'm²',
                decimal: 0,
              },
              {
                key: 'solarthermal_free_operating_hours_h_per_year',
                unit: 'h/a',
                decimal: 0,
              },
              {
                key: 'solarthermal_free_heat_production_mwh',
                unit: 'MW',
                decimal: 1,
              },
              {
                key: 'solarthermal_free_heat_power_mw',
                unit: 'MWh/a',
                decimal: 0,
              },
              {
                key: 'solarthermal_free_invest_euro',
                unit: 'Tsd. €',
                multiply: 0.001,
                decimal: 0,
              },
            ],
          },
          {
            title: 'Solarthermie (Dach)',
            active: false,
            items: [
              {
                key: 'solarthermal_roof_globalradiation_kwh_per_m2',
                unit: 'kWh/m²',
                decimal: 0,
              },
              {
                key: 'solarthermal_roof_solar_heat_area_m2',
                unit: 'm²',
                decimal: 0,
              },
              {
                key: 'solarthermal_roof_operating_hours_h_per_year',
                unit: 'h/a',
                decimal: 0,
              },
              {
                key: 'solarthermal_roof_heat_power_kw',
                unit: 'MW',
                multiply: 0.001,
                decimal: 1,
              },
              {
                key: 'solarthermal_roof_heat_production_kwh',
                unit: 'MWh/a',
                decimal: 0,
                multiply: 0.001,
              },
              {
                key: 'solarthermal_roof_invest_euro',
                unit: 'Tsd. €',
                multiply: 0.001,
                decimal: 0,
              },
            ],
          },
          {
            title: 'Biomasse',
            active: false,
            items: [
              {
                key: 'potential_biomass_mean_cluster_holzeinschlag_cbm_ha',
                unit: 'cbm/ha',
                decimal: 1,
              },
              {
                key: 'potential_biomass_mean_cluster_energieholz_percentage',
                unit: '%',
                decimal: 1,
                multiply: 100,
              },
              {
                key: 'potential_biomass_mean_cluster_energieholz_cbm_per_ha',
                unit: 'cbm/ha',
                decimal: 1,
              },
              {
                key: 'potential_biomass_mean_cluster_heizwert_kwh_per_cbm',
                unit: 'kWh/cbm',
                decimal: 0,
              },
              {
                key: 'potential_biomass_mean_cluster_spez_biomasseertrag_kwh_per_ha',
                unit: 'kWh/ha',
                decimal: 0,
              },
              {
                key: 'potential_biomass_biomasseertrag_kwh',
                unit: 'MWh/a',
                decimal: 0,
                multiply: 0.001,
              },
            ],
          },
          {
            title: 'Abwärme',
            active: false,
            items: [
              {
                key: 'potential_waste_heat_abwaermepotenzial_niedrig_mwh',
                unit: 'MWh/a',
                decimal: 0,
              },
              {
                key: 'potential_waste_heat_abwaermepotenzial_hoch_mwh',
                unit: 'MWh/a',
                decimal: 0,
              },
            ],
          },
          {
            title: 'Wind',
            active: false,
            items: [
              {
                key: 'potential_wind_area_ha',
                unit: 'ha',
                decimal: 0,
              },
              {
                key: 'potential_wind_amount_turbines',
                unit: '',
                decimal: 0,
              },
              {
                key: 'potential_wind_power_park',
                unit: 'MW',
                decimal: 1,
              },
              {
                key: 'potential_wind_operating_hours_turbine',
                unit: 'h/a',
                decimal: 0,
              },
              {
                key: 'potential_wind_production_park',
                unit: 'MWh/ha',
                decimal: 0,
              },
              {
                key: 'potential_wind_invest_park',
                unit: 'Tsd. €',
                decimal: 0,
                multiply: 0.001,
              },
            ],
          },
        ],
      },
    ],
  };
  if (Object.keys(vorrang).length) {
    sidebar.chips.push(vorrang);
  }
  return sidebar;
}

export function turnOffSwitches(activeSwitch) {
  const switches = [
    SWITCH_ID__BLOCK,
    SWITCH_ID__DISTRICT,
    SWITCH_ID__MUNICIPALITY,
    SWITCH_ID__CUSTOM,
    SWITCH_ID__COUNTY,
    SWITCH_ID__GOVERNMENT_DISTRICT,
    SWITCH_ID__FEDERAL_STATE,
    SWITCH_ID__BKG_RASTER,
  ];
  const index = switches.indexOf(activeSwitch);
  return switches.filter((_, i) => i !== index);
}
