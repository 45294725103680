export const LAYER_KEY__INVENTORY_HEAT_NETWORK = 'existing_network';
export const LAYER_KEY__INVENTORY_GAS_NETWORK = 'existing_gas_network';

export const LAYER_KEY__EXISTING_NETWORK_AREAS = 'existing_network_areas';
export const LAYER_KEY_EXISTING_HYDROGEN_NETWORK = 'existing_hydrogen_network';

export default {
  [LAYER_KEY__INVENTORY_HEAT_NETWORK]: {
    layoutState: 'default',
    visible: false,
    layerConfig: {
      type: 'line',
      id: LAYER_KEY__INVENTORY_HEAT_NETWORK,
      sourceLayer: LAYER_KEY__INVENTORY_HEAT_NETWORK,
      layerId: LAYER_KEY__INVENTORY_HEAT_NETWORK,
      visibility: 'none',
      dependsOnScenario: false,
      applyYearlyFilter: false,
      applyMunicipalityFilter: true,
    },
    style: {
      default: {
        lineColor: '#42005c',
        lineWidth: [
          'interpolate',
          ['exponential', 0.5],
          ['zoom'],
          15,
          1.5,
          18,
          2.5,
        ],
      },
    },
    legend: {
      default: {
        name: 'Bestandsinfrastruktur',
        unit: '',
        items: [
          {
            color: '#42005c',
            name: 'Fernwärme',
            icon: 'mdi-minus',
          },
        ],
      },
    },
  },
  [LAYER_KEY__INVENTORY_GAS_NETWORK]: {
    layoutState: 'default',
    visible: false,
    layerConfig: {
      type: 'line',
      id: LAYER_KEY__INVENTORY_GAS_NETWORK,
      sourceLayer: LAYER_KEY__INVENTORY_GAS_NETWORK,
      layerId: LAYER_KEY__INVENTORY_GAS_NETWORK,
      visibility: 'none',
      dependsOnScenario: false,
      applyYearlyFilter: false,
      applyMunicipalityFilter: true,
    },
    style: {
      default: {
        lineColor: '#fc9f23',
        lineWidth: [
          'interpolate',
          ['exponential', 0.5],
          ['zoom'],
          15,
          1.5,
          18,
          2.5,
        ],
      },
    },
    legend: {
      default: {
        name: 'Bestandsinfrastruktur',
        unit: '',
        items: [
          {
            color: '#fc9f23',
            name: 'Erdgas',
            icon: 'mdi-minus',
          },
        ],
      },
    },
  },
  [LAYER_KEY__EXISTING_NETWORK_AREAS]: {
    layoutState: 'default',
    visible: false,
    layerConfig: {
      type: 'polygon',
      id: LAYER_KEY__EXISTING_NETWORK_AREAS,
      sourceLayer: LAYER_KEY__EXISTING_NETWORK_AREAS,
      layerId: LAYER_KEY__EXISTING_NETWORK_AREAS,
      visibility: 'none',
      dependsOnScenario: false,
      applyYearlyFilter: false,
      applyMunicipalityFilter: true,
    },
    style: {
      default: {
        fillColor: '#B4A5FE',
        fillOpacity: 0.5,
        fillOutlineColor: '#B4A5FE',
      },
    },
    legend: {
      default: {
        name: 'Bestandsinfrastruktur',
        unit: '',
        items: [
          {
            color: '#B4A5FE',
            name: 'Wärmenetzgebiete',
            icon: 'mdi-minus',
          },
        ],
      },
    },
  },
  [LAYER_KEY_EXISTING_HYDROGEN_NETWORK]: {
    layoutState: 'default',
    visible: false,
    layerConfig: {
      type: 'polygon',
      id: LAYER_KEY_EXISTING_HYDROGEN_NETWORK,
      sourceLayer: LAYER_KEY_EXISTING_HYDROGEN_NETWORK,
      layerId: LAYER_KEY_EXISTING_HYDROGEN_NETWORK,
      visibility: 'none',
      dependsOnScenario: false,
      applyYearlyFilter: false,
      applyMunicipalityFilter: true,
    },
    style: {
      default: {
        fillColor: '#FFD667',
        fillOpacity: 0.5,
        fillOutlineColor: '#FFD667',
      },
    },
    legend: {
      default: {
        name: 'Bestandsinfrastruktur',
        unit: '',
        items: [
          {
            color: '#FFD667',
            name: 'Wasserstoffnetzgebiete',
            icon: 'mdi-minus',
          },
        ],
      },
    },
  },
};
